import React from 'react';

export enum AuthState {
  LOGGED_OUT,
  LOGGED_IN,
  PENDING,
}

export interface IRetoolIdentity {
  firstName?: string;
  lastName?: string;
  groups?: Array<{ name: string }>;
}

export interface IAuthContext {
  authState: AuthState;
  user: IRetoolIdentity;
  logout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<IAuthContext>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}
