import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

interface ICastorTroyTertiaryApp {
  title: string;
  path2?: string;
  disabled?: boolean;
}

export default function TertiaryNav() {
  const [tertiaryNavItems, setTertiaryNavItems] = React.useState<
    ICastorTroyTertiaryApp[]
  >([]);
  const location = useLocation();

  useEffect(() => {
    // Tertiary nav items are sent by the individual Retool apps via a postMessage. We look for the `tertiaryNav` param.
    const handler = (event: any) => {
      if (Array.isArray(event?.data?.tertiaryNav)) {
        setTertiaryNavItems(event.data.tertiaryNav);
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  const isSelected = (item) => {
    return (
      item.path2 === location.pathname ||
      item.path2.replace('/basics', '') === location.pathname
    );
  };

  return (
    <Drawer className='tertiary-nav' variant='permanent' anchor='left'>
      <List>
        {tertiaryNavItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              disabled={item.disabled ?? false}
              component={Link}
              to={item.path2 ?? 'nopath2'}
            >
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  fontWeight: isSelected(item) ? 'bold' : 'normal',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
