import React, { Fragment, useState } from 'react';
import Retool from 'react-retool';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Header from './ui/Header';
import TertiaryNav from './ui/TertiaryNav';
import Prompt from './utils/prompt';

import './App.css';
import './assets/fonts/Gilroy.css';
import { AuthProvider } from './auth/AuthProvider';
import { HandleReturnFromAuth } from './auth/HandleReturnFromAuth';
import { RequireAuth } from './auth/RequireAuth';
import { AuthState, useAuth } from './auth/AuthContext';

const RETOOL_HOST = process.env.REACT_APP_RETOOL_HOST;

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // Should we show the tertiary nav?
  const [showTertiaryNav, setShowTertiaryNav] = useState<boolean>(false);
  const [dirtyForm, setDirtyForm] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowTertiaryNav(location.pathname.startsWith('/campaigns/') || location.pathname.startsWith('/contentsource/'));
  }, [location]);

  React.useEffect(() => {
    // Tertiary nav items are sent by the individual Retool apps via a postMessage. We look for the `tertiaryNav` param.
    const handler = (event: any) => {
      if (event?.data?.navigate) {
        navigate(event.data.navigate);
      }
      if (typeof event?.data?.formDirty === 'boolean') {
        setDirtyForm(event?.data?.formDirty);
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [navigate]);

  return (
    <AuthProvider>
      <Prompt
        when={dirtyForm}
        message='Unsaved changes detected, continue?'
        beforeUnload={true}
      />
      <Routes>
        <Route element={<Layout showTertiaryNav={showTertiaryNav} />}>
          <Route index element={<HandleReturnFromAuth />} />
          <Route
            path='/:path1/:path2?/:path3?'
            element={
              <RequireAuth>
                <CastorTroyApp showTertiaryNav={showTertiaryNav} />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout({ showTertiaryNav }) {
  const auth = useAuth();

  return (
    <div className='app'>
      <Header />
      {auth.authState === AuthState.PENDING && (
        <div className='auth-check'>
          <span>Please wait...</span>
        </div>
      )}
      {auth.authState !== AuthState.PENDING && (
        <Fragment>
          {showTertiaryNav && <TertiaryNav />}
          <Outlet />
        </Fragment>
      )}
    </div>
  );
}

function CastorTroyApp({ showTertiaryNav }) {
  const { path1, path2, path3 } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const section = path1;
  let app = path2;
  let params: string | undefined;

  // Special-case the 'campaigns' apps.
  if (section === 'campaigns') {
    if (path2 === undefined) {
      app = 'overview';
    } else if (path2 === 'new') {
      app = 'basics';
    } else if (path3 === undefined) {
      app = 'basics';
      params = `campaignId=${path2}`;
    } else if (path3) {
      app = path3;
      params = `campaignId=${path2}`;
    }
  }

  // Special-case the 'contentsource' apps.
  if (section === 'contentsource-streaming-details') {
    if (search) {
      params = queryParams.toString();
    }
  }

  const fullAppName = app !== undefined ? `${section}-${app}` : section;
  console.log(`https://${RETOOL_HOST}/apps/castor-troy/${fullAppName}?_embed=true&${params}`)
  return (
    <div
      className={`retool-app ${
        showTertiaryNav ? 'retool-app-with-tertiary-nav' : ''
      }`}
    >
      <Retool
        url={`https://${RETOOL_HOST}/apps/castor-troy/${fullAppName}?_embed=true&${params}`}
      />
    </div>
  );
}

export default App;
