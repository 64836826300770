import React, { useEffect, useState } from 'react';
import { clearCookies, getCookie } from '../utils/cookies';
import {
  AuthContext,
  IAuthContext,
  AuthState,
  IRetoolIdentity,
} from './AuthContext';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthState>(AuthState.PENDING);
  const [user, setUser] = useState<IRetoolIdentity | undefined>(undefined);

  useEffect(() => {
    const fetchRetoolIdentity = async (): Promise<IRetoolIdentity | void> => {
      const xsrf = getCookie('xsrfToken');
      if (xsrf === undefined) {
        // Missing Retool session cookie. User needs to authenticate.
        return;
      }

      // Retool session cookie present. Checking for active session.
      const response = await fetch('/api/user', {
        method: 'GET',
        headers: {
          'x-xsrf-token': xsrf,
        },
      });

      if (response.status === 200) {
        const json = await response.json();
        return json.user;
      }
    };

    if (authState === AuthState.PENDING) {
      fetchRetoolIdentity().then((retoolUser) => {
        setUser(retoolUser ? retoolUser : undefined);
        setAuthState(retoolUser ? AuthState.LOGGED_IN : AuthState.LOGGED_OUT);
      });
    }
  }, [authState]);

  const logout = function (callback: VoidFunction) {
    clearCookies();
    setAuthState(AuthState.LOGGED_OUT);
    setUser(undefined);
    if (callback) callback();
  };

  const value: IAuthContext = {
    authState,
    user: user as IRetoolIdentity,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
