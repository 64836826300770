import { MenuItem } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type HeaderMenuAppProps = {
  title: string;
  path: string;
};

export default function HeaderMenuApp(props: HeaderMenuAppProps) {
  return (
    <MenuItem component={RouterLink} to={props.path} key={props.title}>
      {props.title}
    </MenuItem>
  );
}
