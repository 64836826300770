import { useAuth } from './AuthContext';

export interface IRoleCheckFlags {
  isCampaignCreator: boolean;
  isSales: boolean;
  isCatalogManager: boolean;
}

export function useRoleCheckFlags(): IRoleCheckFlags {
  const { user } = useAuth();

  const isPartOfSomeGroup = (groupNames: string[]): boolean => {
    return (
      user?.groups?.some((group) =>
        groupNames.includes(group.name.toLowerCase()),
      ) ?? false
    );
  };

  return {
    isCampaignCreator: isPartOfSomeGroup(['campaigncreator', 'admin']),
    isSales: isPartOfSomeGroup(['sales', 'admin']),
    isCatalogManager: isPartOfSomeGroup(['catalogmanager', 'admin']),
  };
}
