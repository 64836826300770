import { Button, Menu } from '@mui/material';
import React, { Fragment } from 'react';
import { FaCaretDown } from "react-icons/fa";

export type HeaderMenuItemProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

export default function HeaderMenuItem(props: HeaderMenuItemProps) {
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <Fragment key={props.title}>
      <Button
        className={`nav-item ${isOpen ? ' current-nav-item' : ''}`}
        onClick={() => setIsOpen(true)}
        ref={menuButtonRef}
        endIcon={
            <FaCaretDown
                style={{ fontSize: '0.8em' }}
                className={`nav-item-icon ${isOpen ? 'current-nav-item-icon' : ''}`}
            />
        }
      >
        {props.title}
      </Button>
      <Menu
        anchorEl={menuButtonRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
      >
        {props.children}
      </Menu>
    </Fragment>
  );
}
