import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRoleCheckFlags } from './useRoleCheckFlags';
import { AuthState, useAuth } from './AuthContext';

export function HandleReturnFromAuth() {
  const auth = useAuth();
  const { isCampaignCreator, isSales } = useRoleCheckFlags();

  if (!auth.user && auth.authState !== AuthState.PENDING) {
    window.location.replace('/googlelogin?origin=login');
    return null;
  }

  // If the user was redirected to authenticate, bring them to their desired destination.
  const destination = sessionStorage.getItem('castor-troy-destination');
  if (destination) {
    sessionStorage.removeItem('castor-troy-destination');
    return <Navigate replace to={destination} />;
  }

  if (isCampaignCreator) {
    return <Navigate replace to='/campaigns' />;
  }

  if (isSales) {
    return <Navigate replace to='/members-search-preview' />;
  }

  return null;
}
