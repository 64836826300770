import React from 'react';
import HeaderMenuItem from './HeaderMenuItem';
import HeaderMenuApp from './HeaderMenuApp';
import { useRoleCheckFlags } from '../auth/useRoleCheckFlags';

export default function HeaderMenu() {
  const { isCampaignCreator, isSales, isCatalogManager } = useRoleCheckFlags();

  return (
    <div className='nav'>
      {isCampaignCreator && (
        <HeaderMenuItem title='Campaigns'>
          <HeaderMenuApp title='IOI & Deals' path='/campaigns' />
          {isCatalogManager && (
              <HeaderMenuApp title='ContentSource' path='/contentsource-overview' />
          )}
        </HeaderMenuItem>
      )}

      {isCampaignCreator && (
        <HeaderMenuItem title='Accounting'>
          <HeaderMenuApp
            title='Sampling Line Items'
            path='/accounting/sampling-line-items'
          />
          <HeaderMenuApp
            title='Revenue Recognition Report'
            path='/accounting/revenue-recognition-report'
          />
        </HeaderMenuItem>
      )}

      {isCampaignCreator && (
        <HeaderMenuItem title='Clients'>
          <HeaderMenuApp title='Clients Overview' path='/clients' />
        </HeaderMenuItem>
      )}

      {isCatalogManager && (
          <HeaderMenuItem title='Catalog'>
            <HeaderMenuApp title='BV Catalog Sync' path='/catalog-client-sync' />
            <HeaderMenuApp title='CAS Catalog' path='/catalog-products' />
            <HeaderMenuApp title='CAS Brands' path='/catalog-brands' />
          </HeaderMenuItem>
      )}

      {(isSales || isCampaignCreator) && (
        <HeaderMenuItem title='Members'>
          <HeaderMenuApp
            title='Members Search Preview'
            path='/members-search-preview'
          />
        </HeaderMenuItem>
      )}
    </div>
  );
}
