import Link from '@mui/material/Link';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import { AuthState, useAuth } from '../auth/AuthContext';

export default function Header() {
  const auth = useAuth();

  return (
    <header className='header'>
      <div className='logo'></div>
      <div className='pre-nav'>
        <div className='title'>CAS Administration</div>
        <AuthStatus />
      </div>
      {auth.authState === AuthState.LOGGED_IN && <HeaderMenu />}
      <div className='clear'></div>
    </header>
  );
}

function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.user) {
    return <span>Please log in to continue.</span>;
  }

  return (
    <div className='welcome'>
      <span>Welcome, {auth.user.firstName}.&nbsp;</span>(
      <Link
        onClick={() => {
          auth.logout(() => navigate('/'));
        }}
      >
        logout
      </Link>
      )
    </div>
  );
}
